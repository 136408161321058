<template>
  <div class="bar-code">
    <p class="row-p">
      <el-button size="medium" type="primary" @click="showAddOrder">
        新增
      </el-button>
    </p>
    <MyTable
      :tableData="track.OrderList"
      :configTable="configTable"
      @handleClose="handleClose"
    ></MyTable>
    <el-pagination
      @current-change="orderListChange"
      :current-page="track.orderPagination.currentPage"
      :page-size="10"
      background
      layout="total, prev, pager, next"
      :total="track.orderPagination.total"
    >
    </el-pagination>
    <el-dialog
      title="新增工单"
      :visible.sync="track.OrderFormVisible"
      width="700px"
      :close-on-click-modal="false"
    >
      <div class="orderDialog">
        <el-input
          class="el-input-name"
          v-show="!track.isNext"
          placeholder="输入工单名"
          v-model="track.orderName"
          maxlength="10"
          show-word-limit
        ></el-input>
        <p class="el-select-p" v-show="!track.isNext">
          <el-select
            v-model="track.typeId"
            placeholder="请选择品种分类"
            @change="trackProcess_searchCrops"
            filterable
            remote
            :remote-method="getTypeResult"
            :loading="loading"
          >
            <el-option
              v-for="item in track.typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="track.cropsId"
            placeholder="请选择作物名称"
            @change="searchBatch_order"
            filterable
            remote
            :remote-method="getCropsResult"
            :loading="loading"
          >
            <el-option
              v-for="item in track.cropsList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="track.batchId"
            placeholder="请选择批次名称"
            filterable
            remote
            :remote-method="getBatchResult"
            :loading="loading"
          >
            <el-option
              v-for="item in track.batchList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </p>
        <ul class="model-ul" v-show="!track.isNext">
          <li
            v-for="item in track.qrcodeModelList"
            :key="item.id"
            @click="changeOrderModel(item)"
            :class="{ isActive: track.selectOrderModel.id == item.id }"
          >
            <div>
              <img :src="uploadImgs + item.photo" alt="" />
            </div>
            <p>{{ item.title }}</p>
          </li>
        </ul>
        <div>
          <div v-show="track.isNext">
            <div>
              <ul>
                <li>
                  <el-input
                    id="modelAttrName"
                    v-show="track.modelAttr.hasName"
                    class="code-item"
                    placeholder="输入商品名"
                    v-model="track.modelAttr.name"
                    @input="setOrderProduct"
                  ></el-input>
                </li>
                <li>
                  <el-input
                    id="modelAttrDate"
                    v-show="track.modelAttr.hasDate"
                    class="code-item"
                    placeholder="输入日期"
                    v-model="track.modelAttr.date"
                    @input="setOrderDate"
                  ></el-input>
                </li>
                <li>
                  <el-input
                    id="modelAttrCompany"
                    v-show="track.modelAttr.hasCompany"
                    class="code-item"
                    placeholder="输入公司"
                    v-model="track.modelAttr.company"
                    @input="setOrderCompany"
                  ></el-input>
                </li>
              </ul>
            </div>
            <p class="code-area-p">
              <span>号码段&nbsp;</span>
              <el-input
                class="codeArea"
                placeholder="输入开始号码"
                v-model="track.start_num"
              ></el-input>
              —
              <el-input
                class="codeArea"
                placeholder="输入结束号码"
                v-model="track.stop_num"
              ></el-input>
            </p>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="track.OrderFormVisible = false">
          取&emsp;消
        </el-button>
        <el-button v-show="!track.isNext" type="primary" @click="orderNext()">
          下一步
        </el-button>
        <el-button
          v-show="track.isNext"
          type="primary"
          @click="track.isNext = false"
        >
          上一步
        </el-button>
        <el-button v-show="track.isNext" type="primary" @click="addOrder()">
          保&emsp;存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQrcodeListApi,
  getProductListApi,
  getProductClassListApi,
  getBatchListApi,
  getOrderListApi,
  postaddOrderApi,
  delOrderApi,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    MyTable: () => import("@/components/MyTable.vue"),
  },
  data() {
    return {
      configTable: [
        {
          prop: "title",
          label: "工单名",
          width: "317",
        },
        {
          prop: "ptitle",
          label: "作物",
          width: "274",
        },
        {
          prop: "batch_title",
          label: "批次",
          width: "270",
        },
        {
          prop: "number",
          label: "号段",
          width: "351",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "328",
        },
        {
          prop: "",
          label: "操作",
          width: "",
          closeType: true,
        },
      ],
      tableData: [],
      total: 20,
      last_page: 1,
      per_page: 10,
      current_page: 1,

      loading: false,
      track: {
        typeId: null,
        typeList: [],
        cropsId: null,
        cropsList: [],
        batchId: null,
        batchList: [],
        OrderFormVisible: false,
        OrderList: [],
        orderPagination: {
          currentPage: 1,
          total: 0,
        },
        qrcodeModelList: [],
        selectOrderModel: {},
        isNext: false,
        modelContent: "",
        start_num: null,
        stop_num: null,
        orderName: "",
        orderKind: 1,
        modelAttr: {
          name: "黄心小西瓜",
          date: "2021.10.13",
          company: "安徽大旭智能科技有限公司",
          hasName: false,
          hasDate: false,
          hasCompany: false,
        },
      },
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getOrderList();
    this.getTypeList();
  },
  methods: {
    async orderListChange(val) {
      let temp = {};
      if (val) temp.page_num = val;
      temp.page_size = 10;
      const { data: res } = await getOrderListApi({ params: temp });
      this.track.OrderList = res.results.data;
      this.track.orderPagination.total = res.results.count;
    },
    /* 远程搜索作物 */
    getCropsResult(query) {
      if (query != "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const { data: res } = await getProductListApi({
            cid: this.track.typeId,
            title: query,
          });
          this.track.cropsList = res.results.data;
        }, 200);
      } else {
        this.track.cropsList = [];
      }
    },
    /* 选择条码模板 */
    changeOrderModel(item) {
      this.track.selectOrderModel = item;
    },
    /* 设置采收日期 */
    setOrderDate() {
      document.getElementById("modelAttrDate").innerHTML =
        this.track.modelAttr.date;
    },
    /* 设置生产商 */
    setOrderCompany() {
      document.getElementById("modelAttrCompany").innerHTML =
        this.track.modelAttr.company;
    },
    /* 保存工单 */
    async addOrder() {
      if (parseInt(this.track.start_num) > parseInt(this.track.stop_num)) {
        this.$message.error("开始号码不能大于截止号码");
        return;
      }
      let tempOrder = {};
      tempOrder.manor_id = this.farmID;
      tempOrder.pid = this.track.cropsId;
      tempOrder.title = this.track.orderName;
      tempOrder.batch_id = this.track.batchId;
      tempOrder.kind = this.track.orderKind;
      tempOrder.start_num = parseInt(this.track.start_num);
      tempOrder.stop_num = parseInt(this.track.stop_num);
      tempOrder.template_id = this.track.selectOrderModel.id;
      tempOrder.param = {};
      tempOrder.param.作物 = this.track.modelAttr.name;
      tempOrder.param.日期 = this.track.modelAttr.date;
      tempOrder.param.商家 = this.track.modelAttr.company;
      const res = await postaddOrderApi(tempOrder);

      if (res.code !== 0) return;
      this.getOrderList();
      this.track.OrderFormVisible = false;
    },
    async orderNext() {
      this.track.modelAttr.hasName = false;
      this.track.modelAttr.hasDate = false;
      this.track.modelAttr.hasCompany = false;
      if (this.track.orderName.length < 2) {
        this.$message.error("工单名长度不能低于2字符");
        return;
      }
      if (!this.track.typeId) {
        this.$message.error("需要选择批次");
        return;
      }
      if (!this.track.selectOrderModel.id) {
        this.$message.error("需要选择条码模板");
        return;
      }

      this.$nextTick(function () {
        if (document.getElementById("modelAttrName")) {
          this.track.modelAttr.hasName = true;
          let crop = this.track.cropsList.find((item) => {
            return item.id == this.track.cropsId;
          });
          this.track.modelAttr.name = crop.title;
          this.setOrderProduct();
        }
        if (document.getElementById("modelAttrDate")) {
          this.track.modelAttr.hasDate = true;
          this.track.modelAttr.date = this.getCurrentDate();
          this.setOrderDate();
        }
        if (document.getElementById("modelAttrCompany")) {
          this.track.modelAttr.hasCompany = true;
          this.track.modelAttr.company = "xxxx有限公司";
          this.setOrderCompany();
        }
      });
      this.track.isNext = true;
    },
    getTypeResult(query) {
      if (query != "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const { results } = await getProductClassListApi({
            manor_id: this.farmID,
            name: query,
          });
          this.track.typeList = results.data;
        }, 200);
      } else {
        this.track.typeList = [];
      }
    },
    setOrderProduct() {
      document.getElementById("modelAttrName").innerHTML =
        this.track.modelAttr.name;
    },
    getCurrentDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 1 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentDate = year + "." + month + "." + strDate;
      return currentDate;
    },
    async getTypeList() {
      const { code, results } = await getProductClassListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.track.typeList = results.data;
      }
    },
    async trackProcess_searchCrops() {
      this.track.cropsId = null;
      this.track.batchId = null;
      this.track.batchList = [];

      const { code, results } = await getProductListApi({
        cid: this.track.typeId,
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.track.cropsList = results.data;
      }
    },
    async getOrderList() {
      const { results, code } = await getOrderListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.track.OrderList = results.data;
        this.track.orderPagination.total = results.count;
        this.$forceUpdate();
      }
    },
    getBatchResult(query) {
      if (query != "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const { data: res } = await getBatchListApi({
            product_id: this.track.cropsId,
            title: query,
          });
          this.track.batchList = res.results.data;
        }, 200);
      } else {
        this.track.batchList = [];
      }
    },
    async searchBatch_order() {
      this.track.batchId = null;
      const { code, results } = await getBatchListApi({
        product_id: this.track.cropsId,
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.track.batchList = results.data;
      }
    },
    initAddOrder() {
      this.track.orderName = "";
      this.track.typeId = null;
      this.track.cropsId = null;
      this.track.batchId = null;
      this.track.cropsList = [];
      this.track.batchList = [];
      this.track.selectOrderModel = {};
      this.track.start_num = null;
      this.track.stop_num = null;
    },
    async showAddOrder() {
      this.initAddOrder();
      const { results, code } = await getQrcodeListApi();
      if (code === 0) {
        this.track.qrcodeModelList = results.data;
      }
      this.track.isNext = false;
      this.track.OrderFormVisible = true;
    },
    handleClose({ id }) {
      this.$confirm("确认关闭该工单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await delOrderApi({
            oid: id,
          });
          this.getOrderList();
          this.$notify({
            title: "成功",
            message: "已关闭",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    handleCurrentChange(val) {
      this.current_page = val;
    },
  },
};
</script>

<style lang="less" scoped>
.bar-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #071a2c;
  padding: 19px 47px;
  .row-p {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  /deep/.el-pagination {
    margin-top: 20px;
  }

  /deep/.orderDialog {
    .el-input-name,
    .el-select-p {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .el-select {
        width: 30%;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .model-ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 30%;
        height: 200px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333;
        border: 1px solid #ccc;
        margin: 0 10px 10px 0;
        &.isActive {
          border-color: #3e90e5;
        }
        img {
          width: 180px;
        }
      }
    }
    .code-area-p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .codeArea.el-input {
        width: 260px;

        .el-input__inner {
          width: 100%;
        }
      }
    }
  }
}
/deep/.code-item {
  margin-bottom: 20px;
}
</style>
